.products {
    width: 90%;
    margin: auto;
    padding-top: 130px;
}

.products__container {
    width: 100%;
}

.products__title {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: var(--secundary);
    background: var(--primary-dark);
    padding: 2px 0 2px 25px;
    border-radius: 5px 50px 5px 50px;
}