.footer {
    width: 100%;
}

.footer__main {
    width: 100%;
    background: var(--primary-dark);
    display: flex;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;

}

.footer__img {
    width: 300px;
}

.footer__links {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.fl__item a {
    color: var(--primary);
}


.fl__item a:hover {
    color: var(--white);
}

.fl__title {
    font-size: 1.2rem;
    color: var(--white);
    font-weight: 500;
}

.footer__btns {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.fb__btn {
    width: 50px;
    height: 50px;
    background: var(--secundary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.8rem;
    color: var(--primary);
    cursor: pointer;
    transition: all 0.5s ease;
}

.fb__btn:hover {
    background: var(--secundary-dark);
}

.footer__copy {
    text-align: center;
    font-size: 1.1rem;
    padding: 10px;
    background: var(--secundary);
    color: var(--white);
}

@media screen and (min-width: 639px) {
    .footer__main {
        justify-content: flex-start;
    }
}