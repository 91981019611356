.distributors {
    width: 90%;
    margin: 50px auto;
    background-image: url("https://i.ibb.co/598BTj1/violin.jpg");
    background-position: center 20%;
    background-size: cover;
    border-radius: 20px;
}

.distributors__container {
    width: 100%;
    height: 100%;
    background-color: #6d3d337c;
    padding: 10px;
    border-radius: 20px;
}

.d__title {
    text-align: center;
    font-size: 2rem;
    margin: 20px 0;
    color: var(--white);
}

.d__list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.dl__image {
    width: 150px;
}