.slider {
    width: 100%;
    position: relative;
    display: none;
}

.slider__container {
    width: 310px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.slider__list {
    width: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    transition: all 1s ease;
}

.slider__btn-close {
    padding: 10px 15px;
    border-radius: 50%;
    font-size: 1.2rem;
    background: rgb(0, 0, 0, 0.2);
    color: var(--white);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.show {
    display: block;
}

.slider__btn-Arrow {
    width: 30px;
    height: 30px;
    background: var(--secundary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--white);
    cursor: pointer;
}

.slider__btn-Arrow:hover {
    background: var(--secundary-dark);
}

.btn-left {
    position: absolute;
    top: calc(100% - 50%);
    left: 10px;
}

.btn-right {
    position: absolute;
    top: calc(100% - 50%);
    right: 10px;
}

.none {
    display: none;
}

@media screen and (min-width: 800px) {
    .slider__container {
        width: 790px;
    }

    .slider__btn-Arrow {
        display: none;
    }

    .slider__list {
        padding-left: 0px;
    }
}