.productImgs {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.productImgs__main {
    width: 250px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 1px 12px 15px rgba(0, 0, 0, 0.531);
    cursor: zoom-in;
}

.main__img {
    width: 250px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
    transition: all 0.5s ease;
}

.main__img:hover {
    opacity: 0;
}

.productImgs__thumbs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.thumbs__item {
    width: 80px;
    border: 1px solid var(--secundary-dark);
    border-radius: 5px;
    cursor: pointer;
}