.card {
    width: 250px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
}

.card__img {
    width: 250px;
}

.card__title {
    color: var(--secundary);
    font-size: 1.5rem;
}

.card__description {
    color: var(--secundary-dark);
}

.card__btn {
    font-size: 0.7rem;
    text-align: center;
    width: 50px;
    padding: 3px 8px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    background: var(--secundary);
    color: var(--white);
    font-weight: 600;
    transition: all 0.5s ease;
}

.card__btn:hover {
    background: var(--secundary-dark);
}