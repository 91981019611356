.hero {
    width: 100%;
    height: 70vh;
    background-image: url("https://i.ibb.co/SV8y4kF/hero-Alber.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.hero__title {
    color: var(--white);
    font-size: 2.5rem;
    text-shadow: 1px 1px 1px var(--secundary);
    margin-top: 150px;
    text-align: center;
}

.hero__btn {
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 1.1rem;
    color: var(--white);
    background: var(--secundary);
    cursor: pointer;
    transition: all 0.5s ease;
}

.hero__btn:hover {
    background: var(--secundary-dark);
}