.collection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.collecion__image {
    width: 100%;
    height: 30rem;
    position: relative;
}

.ci__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ci__textCont {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23292a65;
    text-align: center;
    color: var(--primary);
    padding: 20px;
    font-size: 2rem;
    text-shadow: 1px 1px 1px var(--secundary);
}

.collection__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 25rem;
    gap: 20px;
    color: var(--secundary-dark);
    text-align: center;
}

.ct__btn {
    padding: 20px;
    border-radius: 10px;
    background: var(--secundary);
    color: var(--white);
    cursor: pointer;
    transition: all 0.5s ease;
}

.ct__btn:hover {
    background: var(--secundary-dark);
}

.right {
    flex-direction: row-reverse;
}



/* ==================== Media query ============================ */

@media screen and (min-width: 700px) {
    .collecion__image {
        max-width: 50%;
    }

    .collection__text {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}