.head {
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 99;
}

.head__container {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.5);
}

.head__logo {
  width: 98px;
  height: 72px;
}

.head__icons {
  display: flex;
  gap: 10px;

}

.icons__item {
  width: 40px;
  height: 40px;
  color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.icons__item:hover {
  color: var(--primary-dark);
  background-color: var(--primary);
}

.head__list {
  width: 100%;
  top: 100px;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 15px;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: all 0.5s ease;
}

.head__list li {
  padding: 5px;
}

.head__list a {
  color: var(--primary);
  transition: all 0.5s ease;
}

.head__list a:hover {
  color: var(--white);
}

.show--menu {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}