.product {
    width: 100%;
    padding: 125px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.product__title {
    font-size: 2.2rem;
    color: var(--secundary-dark);
}

.product__content {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 60px;
}


.product__description {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.pd__text {
    color: var(--primary-dark);
    font-size: 1.1rem;
    text-align: justify;
}

.pd__listItem {
    color: var(--primary-dark);
    font-size: 1.1rem;
    list-style: inside;
    /* margin-left: 20px; */
}

.pd__title {
    font-size: 1.3rem;
    color: var(--secundary-dark);
    font-weight: 600;
}

.product__dealers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 50px 0;
}

.pde__title {
    color: var(--secundary-dark);
    font-size: 1.5rem;
}



.dealers__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

}

.dealer__img {
    width: 100px;
}