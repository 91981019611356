@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

* {
  border: 0;
  outline: 0;
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --primary: #BEC0BE;
  --primary-dark: #23292A;
  --secundary: #AE6C4C;
  --secundary-dark: #6D3D33;
  --white: #f4f4f9;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--primary);
}