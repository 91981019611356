.contact {
    width: 100%;
    background-image: url("https://i.ibb.co/370yH00/saxohero.jpg");
    background-position: center 20%;
    background-size: cover;
}

.contact__front {
    width: 100%;
    height: 100%;
    background: rgb(190, 192, 190, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    padding: 50px 0;
}

.cd__title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--secundary);
}

.cf__tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 350px;
}

.cf__img {
    width: 250px;
}

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px;
}

input {
    padding: 5px;
    border-radius: 5px;
}

.cf__name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.contact__btn {
    width: 100px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: var(--secundary);
    color: var(--white);
}

.contact__response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact__message {
    color: var(--secundary-dark);
}

.none {
    display: none;
}